<template>
    <div>
        <div   class=" pb-9 diagonal pt-0 pt-md-5">
        <div class="py-4 mt--4 mb--3">
           <h3 class="mb-0 text-white pt-4">Zoznam dostupných</h3>
            <h2 class=" mb--5">Modulov</h2>
            <div class="text-right system-options w-100 d-block" style="">
            </div>
                </div>
            </div>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <div class="card shadow">
                        <div class="table-responsive">
                            <a-table :data-source="data"
                                    :columns="columns"
                                    :pagination="pagination"
                                    :rowKey="record => record.id"
                                    :loading="loading"
                                    class="table-flush">

                                <template slot="name" slot-scope="user">
                                   <span class="actions">
                                        <router-link :to="'/module/' + user.id" class="btn text-default btn-icon btn-secondary btn-sm" :href="'/module/' + user.id">
                                            <span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-pencil-alt"></i></span>Detail
                                        </router-link>
                                   </span>
</template>
                            </a-table>
                        </div>
                    </div>
 </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {dataService} from "../_services";
    import moment from 'moment';
    import locale from 'ant-design-vue/es/date-picker/locale/sk_SK';
    import {IMG_URL} from "../api";
    import {Switch} from 'ant-design-vue';

    const columns = [
        {
            title: 'Názov modulu',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'Vytvorené',
            dataIndex: 'created',
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];
    export default {
        name: "allitems",
        props: ["id","tid"],
        components: {
            "a-switch": Switch
        },
        data() {
            return {
                data: [],
                vars:{},
                colorsto: JSON.parse(localStorage.colors),
                ctt: [],
                loading: false,
                contenttypes:[],
                enabledModules: [],
                disabledModules: [],
                locale,
                moment,
                pagination: {
                    hideOnSinglePage: true,
                    pageSize: 20, // default number of pages per page
                    showSizeChanger: true, // display can change the number of pages per page
                    pageSizeOptions: ['10', '20', '30', '40'], // number of pages per option
                    showTotal: total => `Položiek: ${total}`, // show total
                    showSizeChange: (current, pageSize) => {  this.pageSize = pageSize; }, // update display when changing the number of pages per page
                },
                fieldtitle:"",
                visible: false,
                filterBy:[],
                realFilter:JSON.parse(localStorage.filter),
                perm:JSON.parse(localStorage.user).data.paths,
                filterValues:{},
                actions: [
                    {"value":"install","label":"Inštalovať"},
                    {"value":"activate","label":"Aktivovať"},
                    {"value":"deactivate","label":"Deaktivovať"},
                ],
                thistype:"",

                action:undefined,
                selectValue:[],
                IMG_URL,
                filterAll:{},
                maincategory:null,
                slugfields:[],
                filteredInfo: null,
                columns,
                sortedInfo: null,
                showon: []
            };
        },
        mounted() {
            this.fetch();
        },
        computed: {
        },
        methods: {
                doAction()
                {
                    if(this.action)
                    {
                    dataService.axiosPost('action/' + this.action, { 'modules' : this.rowSelection.selectedRowKeys}).then(() => {
                        this.rowSelection.selectedRowKeys = [];
                        this.fetch();
                    });
                   }
                },
                onChange(e,f)
                {


if(this.enabledModules.includes(f))
  this.enabledModules.splice(this.enabledModules.indexOf(f), 1);
if(this.disabledModules.includes(f))
  this.disabledModules.splice(this.disabledModules.indexOf(f), 1);

if(e === true)
 {

     if(!this.enabledModules.includes(f)){          //checking weather array contain the id
    this.enabledModules.push(f);               //adding to array because value doesnt exists
}

 }
else
   {

     if(!this.disabledModules.includes(f)){          //checking weather array contain the id
    this.disabledModules.push(f);               //adding to array because value doesnt exists
}

   }
                },
                manageModules()
                {
                dataService.axiosPost('managemodules', {'e': this.enabledModules, 'd': this.disabledModules}).then(results => {
                    if (results) {

                    }
                });
                },
        confirm(e) {
                dataService.axiosDelete('content', e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            hasValue(obj, key, value) {
                return obj.hasOwnProperty(key) && obj[key] === value;
            },
            removeFilter(index)
            {
                this.realFilter.splice(index, 1);
                localStorage.setItem('filter', JSON.stringify(this.realFilter));
            },
            filt(w,slug,type)
            {
                 for (var key in this.realFilter) {
                   if (this.realFilter[key]["slug"] === slug)
                   {
                        delete this.realFilter[key];
                   }
                  }
             let filtered =  this.realFilter.filter(function () { return true });
             this.realFilter = filtered;
             this.realFilter.push({"slug": slug, 'type': parseInt(type), "value": w});
             localStorage.setItem('filter', JSON.stringify(this.realFilter));
             },
            cancel() {
            },
            onSelectChange(selectedRowKeys) {
                this.selectedRowKeys = selectedRowKeys;
            },
            filterIt()
            {
            },
            handleChange(pagination, filters, sorter) {
                this.filteredInfo = filters;
                this.sortedInfo = sorter;
            },
            clearFilters() {
                this.filteredInfo = null;
            },
            hide() {
                this.visible = false;
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch("variable/site_url").then(results => {
                    this.vars = results;
                })
                dataService.axiosFetch('getmodules').then(results => {

                    this.data = results;
                    this.loading = false;
                });
            }

        },
    };
</script>

<style>
ul.ant-pagination {
margin-top: 0;
margin-bottom: 1em;
}
.ant-carousel >>> .slick-slide {
text-align: center;
height: 160px;
line-height: 160px;
background: #364d79;
overflow: hidden;
}
#app .smaller .ant-select-selection__rendered,
#app .smaller .ant-select-selection--single
{line-height:40px;height:40px;margin-right:10px;}
.system-options
{width:25%;}
.ant-carousel .custom-slick-arrow {
width: 25px;
height: 25px;
line-height: 20px;
font-size: 14px;
color: #fff;
background-color: rgba(31, 45, 61, 0.41);
border-radius: 100%;
opacity: 0.9;
top:43%;
padding: 4px;
padding-left: 8px;
}
.ant-carousel .custom-slick-arrow:before
{display:none}
.ant-carousel .custom-slick-arrow:hover {
opacity: 0.5;
color:#fff;
background: #2d2d2d;
}

.ant-carousel .slick-slide img {
width:100%;
}
.ant-popover-inner-content
{max-width:500px;}
.actions
{display: block;
width: 175px;
float:right;
text-align: right;}

.extra-link
{
    display:inline-block;
}
.square{
display:block;
width:20px;
height:20px;
}
.nopad a
{padding:0;}

.ant-table-pagination.ant-pagination {
margin-bottom: 40px;
margin-right: 20px;
}
.infield-gallery-image
{float:left;
width:50%;
}
.infield-gallery-image img
{width:100%;}
.ant-pagination5-item {
border: 0;
}

.ant-table {
border-bottom: 1px solid rgba(0, 0, 0, 0.05);
margin-bottom: 20px;
}

.ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a, .ant-pagination5-jump-prev a, .ant-pagination5-jump-next a {
-webkit-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
border: 0;
}
.filter-block .remove-filter
{cursor:pointer;}
.filter-block
{background-color: #fafafa;
border-top-right-radius:10px;
margin-bottom:-10px;
position:relative;z-index:99;
border-top-left-radius:10px;
padding:10px;text-align:right;}
.ant-pagination5-item a, .ant-pagination5-prev a, .ant-pagination5-next a {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
padding: 0;
margin: 0 3px;
border-radius: 50% !important;
width: 36px;
height: 36px;
font-size: 0.875rem;
}


.ant-pagination5-item.ant-pagination5-item-active a {
z-index: 1;
color: #fff;
background-color: #5e72e4;
border-color: #5e72e4;
}
.quick-links label
{font-size:12px;line-height:35px;}
.extramuted
{border-color: #deebff;font-size:12px;
background-color:#fff;}
.extramuted.selected
{border-color:#5e72e4}
.extramuted:hover
{background-color: #5e72e4;
color:#fff!important;}
.ant-pagination5-item-link.ant-pagination5-disabled a {
color: #8898aa;
pointer-events: none;
cursor: auto;
background-color: #fff;
border-color: #dee2e6;
}

.ant-dropdown-open .btn
{    background-color: #5e72e4;
color:#fff!important;
border-color: #5e72e4;}

</style>
